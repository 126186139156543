<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title>Hệ thống đại lý</b-card-title>
      </b-card-header>
      <vue-tree
        style="height: 1000px;"
        :dataset="dataTree"
        :config="treeConfig"
      >
        <template v-slot:node="{ node, collapsed }">
          <div
            class="rich-media-node"
            :style="{ border: collapsed ? '2px solid grey' : '' }"
          >
            <span style="padding: 4px 0; font-weight: bold;">{{ node.name }}</span>
          </div>
        </template>
      </vue-tree>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
} from 'bootstrap-vue'
import VueTree from '@ssthouse/vue-tree-chart'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import useKhachHangList from './useKhachHangList'
import userStoreModule from '../../userStoreModule'

export default {
  components: {
    BCardTitle,
    BCardHeader,
    VueTree,
    BCard,
  },
  data() {
    return {
      currentUser: getUserData(),
      dataTree: {
        name: 'ĐỖ ĐỨC HÙNG',
        value: 1,
        children: [
          {
            name: 'Đại lý 1',
            value: 34,
            children: [
              {
                name: 'Đại lý 2',
                value: 35,
                children: [],
              },
              {
                name: 'Nguyễn Hoàng Sơn',
                value: 44,
                children: [
                  {
                    name: 'Đại lý cấp 2',
                    value: 54,
                    children: [],
                  },
                  {
                    name: 'Đại lý cấp 2.2',
                    value: 55,
                    children: [
                      {
                        name: 'Đại lý cấp 3.1',
                        value: 56,
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    }
  },
  watch: {

  },
  beforeMount() {

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const {
      treeConfig,
    } = useKhachHangList()

    return {
      // Sidebar
      treeConfig,
    }
  },
  mounted() {
    // const currentUser = getUserData()
    // store
    //   .dispatch('app-user/getTree', {
    //     auth: currentUser.auth_key,
    //     uid: currentUser.id,
    //   })
    //   .then(response => {
    //     const { users } = response.data
    //     this.dataTree = users
    //   })
    //   .catch(() => {
    //     this.showToast('danger', 'Thông báo', 'BellIcon', 'Error fetching users list')
    //   })
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
  text-align: center;
}
</style>
